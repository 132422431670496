:root
  --font-family "IBM Plex Mono", monospace

* 
  font-size 1.8rem

asterisk()
  display flex
  padding .2rem 3rem
  margin 0 -3rem
  text-align left
  &:before
    content: "* "
    padding-right 1rem
    
html, body
  padding 0
  margin 0
  overscroll-behavior contain  
html
  color #33ff33
  background-color #282828
  font-size 62.5%
  display flex
  flex-direction column
  align-items center
  justify-content center  
body 
  font-family var(--font-family)
  line-height 1.45
  margin 3rem
  box-sizing border-box
  max-width 64rem
h1 
  font-size 2.4rem
h4
  font-size 2rem
  font-weight normal
  margin 2.4rem 0 .6rem
  max-width 100%
blockquote
  font-style: italic;
  margin-left: 1.6rem;
#display
  .intro-text
    margin 3rem 0
    font-style italic
  .exits
    h5
      font-weight normal
      margin 1.8rem 0 .6rem
  .buttons
    li:hover
      background-color #33ff33
      &:before
        content: "*"
        color: #282828
      button
        color #282828
    button
      flex 1
      background transparent
      text-align left
      color #33ff33
      border 0
      font-family var(--font-family)
      font-size 1.8rem
      cursor pointer
  .object
    display inline-block
    text-transform uppercase
    &:hover
      background-color #33ff33
      color #282828  
#console
  display flex
  align-items center
  max-width 100%
  font-family var(--font-family)
  button#submit
    user-select none
    pointer-events none
    display inline-block
    transition all .06s ease-in-out
    color #aaffaa66;
    text-transform uppercase
    background-color transparent;
    border 0
    border-radius .3rem
    padding .3rem .6rem
    cursor pointer
    opacity .25
    box-shadow inset 0 0 0 .1rem #aaffaa66
    &:hover
      box-shadow 0
    &:active
      background-color #22cc22
    &.shown
      color #282828
      background #33ff33
      pointer-events all
      user-select none
      opacity 1
      transition all .24s ease-in-out
#prompt, .prompt
  font-family var(--font-family)
  flex 1
  font-size 1.8rem
  border 0
  display inline-block
  padding-left .4rem
  background-color: transparent
  color #aaffaa
  margin 0
  &:focus
    outline none
#console, .prompt
  margin-top 1.2rem 
.caret
  display inline-block
  width 1.6rem
  color #aaffaa
  &:before
    content '>'

ul.asterisk
  padding 0
  li
    asterisk()
    position relative
    list-style none
    &:before
      content "*"
      padding-right 1rem

.passport 
  fullWidth()
  p
    margin initial
    margin-top 1rem;
  .visited
    opacity 1
  .not-visited
    opacity .5
  span
    &:after
      content ", "
    &:last-of-type:after
      content ""
.attribution
  position absolute
  bottom 0
  right 0
  background-color rgba(40,40,40,1)
  color #33ff33cc
  font-size xx-small
  padding .2rem 1rem
  a
    font-size xx-small
    color #33ff33cc

#logo
  max-width 100%
  margin 2rem 0
  margin-top -2rem
  padding-top 2rem
  @media (min-width: 640px)
    margin 2rem 32rem 2rem 0  
  &.hidden
    display none

.wrapped-box
  border 1px solid #33ff33
  padding: 1.8rem 2.4rem
  margin 3.6rem 0 3rem

.area-wrapper
  padding-top 50px
  margin-top -50px

.pic
  position relative
  background-size cover
  background-repeat no-repeat
  width 100%
  aspect-ratio 2
  margin 2.4rem auto 3.6rem
  border-radius: .3rem
  .attribution
    opacity 0
  &:hover .attribution
      transition .32s ease-in-out
      transition-delay .32s
      opacity 1
  &.albania
    background-image url("assets/albania.png")
  &.atlantic-ocean
    background-image url("assets/atlantic-ocean.png")    
  &.bosnia-and-herzegovina
    background-image url("assets/bosnia-and-herzegovina.png")
  &.botswana
    background-image url("assets/botswana.png")       
  &.canada
    background-image url("assets/canada.png")
  &.cambodia
    background-image url("assets/cambodia.png")    
  &.china
    background-image url("assets/china.png")
  &.croatia
    background-image url("assets/croatia.png")
  &.cuba
    background-image url("assets/cuba.png")       
  &.czech-republic
    background-image url("assets/czech-republic.png")
  &.dominican-republic
    background-image url("assets/dominican-republic.png")    
  &.eswatini
    background-image url("assets/eswatini.png")
  &.gabon
    background-image url("assets/gabon.png")         
  &.greece
    background-image url("assets/greece.png")
  &.grenada
    background-image url("assets/grenada.png")    
  &.haiti
    background-image url("assets/haiti.png")        
  &.india
    background-image url("assets/india.png")
  &.indonesia
    background-image url("assets/indonesia.png")
  &.italy
    background-image url("assets/italy.png")      
  &.japan
    background-image url("assets/japan.png")
  &.kazakhstan
    background-image url("assets/kazakhstan.png")
  &.kyrgyzstan
    background-image url("assets/kyrgyzstan.png")
  &.libya
    background-image url("assets/libya.png")    
  &.mexico
    background-image url("assets/mexico.png")
  &.mozambique
    background-image url("assets/mozambique.png")    
  &.norway
    background-image url("assets/norway.png")
  &.papua-new-guinea
    background-image url("assets/papua-new-guinea.png")     
  &.peru
    background-image url("assets/peru.png")        
  &.philippines
    background-image url("assets/philippines.png")        
  &.russia
    background-image url("assets/russia.png")    
  &.scotland
    background-image url("assets/scotland.png")
  &.san-marino
    background-image url("assets/san-marino.png")
  &.singapore
    background-image url("assets/singapore.png")
  &.slovenia
    background-image url("assets/slovenia.png")    
  &.sri-lanka
    background-image url("assets/sri-lanka.png")    
  &.tanzania
    background-image url("assets/tanzania.png")   
  &.turkey
    background-image url("assets/turkey.png")       
  // &.united-states
  //   background-image url("assets/united-states.png")
  &.vanuatu
    background-image url("assets/vanuatu.png")
  &.venezuela
    background-image url("assets/venezuela.png")    
  &.yemen
    background-image url("assets/yemen.png")
  