:root {
  --font-family: "IBM Plex Mono", monospace;
}

* {
  font-size: 1.8rem;
}

html, body {
  overscroll-behavior: contain;
  margin: 0;
  padding: 0;
}

html {
  color: #3f3;
  background-color: #282828;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 62.5%;
  display: flex;
}

body {
  font-family: var(--font-family);
  box-sizing: border-box;
  max-width: 64rem;
  margin: 3rem;
  line-height: 1.45;
}

h1 {
  font-size: 2.4rem;
}

h4 {
  max-width: 100%;
  margin: 2.4rem 0 .6rem;
  font-size: 2rem;
  font-weight: normal;
}

blockquote {
  margin-left: 1.6rem;
  font-style: italic;
}

#display .intro-text {
  margin: 3rem 0;
  font-style: italic;
}

#display .exits h5 {
  margin: 1.8rem 0 .6rem;
  font-weight: normal;
}

#display .buttons li:hover {
  background-color: #3f3;
}

#display .buttons li:hover:before {
  content: "*";
  color: #282828;
}

#display .buttons li:hover button {
  color: #282828;
}

#display .buttons button {
  text-align: left;
  color: #3f3;
  font-family: var(--font-family);
  cursor: pointer;
  background: none;
  border: 0;
  flex: 1;
  font-size: 1.8rem;
}

#display .object {
  text-transform: uppercase;
  display: inline-block;
}

#display .object:hover {
  color: #282828;
  background-color: #3f3;
}

#console {
  max-width: 100%;
  font-family: var(--font-family);
  align-items: center;
  display: flex;
}

#console button#submit {
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  color: #afa6;
  text-transform: uppercase;
  cursor: pointer;
  opacity: .25;
  background-color: #0000;
  border: 0;
  border-radius: .3rem;
  padding: .3rem .6rem;
  transition: all 60ms ease-in-out;
  display: inline-block;
  box-shadow: inset 0 0 0 .1rem #afa6;
}

#console button#submit:hover {
  box-shadow: 0;
}

#console button#submit:active {
  background-color: #2c2;
}

#console button#submit.shown {
  color: #282828;
  pointer-events: all;
  -webkit-user-select: none;
  user-select: none;
  opacity: 1;
  background: #3f3;
  transition: all .24s ease-in-out;
}

#prompt, .prompt {
  font-family: var(--font-family);
  color: #afa;
  background-color: #0000;
  border: 0;
  flex: 1;
  margin: 0;
  padding-left: .4rem;
  font-size: 1.8rem;
  display: inline-block;
}

#prompt:focus, .prompt:focus {
  outline: none;
}

#console, .prompt {
  margin-top: 1.2rem;
}

.caret {
  color: #afa;
  width: 1.6rem;
  display: inline-block;
}

.caret:before {
  content: ">";
}

ul.asterisk {
  padding: 0;
}

ul.asterisk li {
  text-align: left;
  margin: 0 -3rem;
  padding: .2rem 3rem;
  list-style: none;
  display: flex;
  position: relative;
}

ul.asterisk li:before {
  content: "* ";
  content: "*";
  padding-right: 1rem;
}

.passport p {
  margin: initial;
  margin-top: 1rem;
}

.passport .visited {
  opacity: 1;
}

.passport .not-visited {
  opacity: .5;
}

.passport span:after {
  content: ", ";
}

.passport span:last-of-type:after {
  content: "";
}

.attribution {
  color: #3f3c;
  background-color: #282828;
  padding: .2rem 1rem;
  font-size: xx-small;
  position: absolute;
  bottom: 0;
  right: 0;
}

.attribution a {
  color: #3f3c;
  font-size: xx-small;
}

#logo {
  max-width: 100%;
  margin: -2rem 0 2rem;
  padding-top: 2rem;
}

@media (width >= 640px) {
  #logo {
    margin: 2rem 32rem 2rem 0;
  }
}

#logo.hidden {
  display: none;
}

.wrapped-box {
  border: 1px solid #3f3;
  margin: 3.6rem 0 3rem;
  padding: 1.8rem 2.4rem;
}

.area-wrapper {
  margin-top: -50px;
  padding-top: 50px;
}

.pic {
  aspect-ratio: 2;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: .3rem;
  width: 100%;
  margin: 2.4rem auto 3.6rem;
  position: relative;
}

.pic .attribution {
  opacity: 0;
}

.pic:hover .attribution {
  opacity: 1;
  transition: all .32s ease-in-out .32s;
}

.pic.albania {
  background-image: url("albania.25594270.png");
}

.pic.atlantic-ocean {
  background-image: url("atlantic-ocean.205b6a77.png");
}

.pic.bosnia-and-herzegovina {
  background-image: url("bosnia-and-herzegovina.238585ac.png");
}

.pic.botswana {
  background-image: url("botswana.3283028f.png");
}

.pic.canada {
  background-image: url("canada.090ac158.png");
}

.pic.cambodia {
  background-image: url("cambodia.e1edda1b.png");
}

.pic.china {
  background-image: url("china.07070a90.png");
}

.pic.croatia {
  background-image: url("croatia.450e7b62.png");
}

.pic.cuba {
  background-image: url("cuba.019199b9.png");
}

.pic.czech-republic {
  background-image: url("czech-republic.836b0002.png");
}

.pic.dominican-republic {
  background-image: url("dominican-republic.7e3d3950.png");
}

.pic.eswatini {
  background-image: url("eswatini.4bfbb51f.png");
}

.pic.gabon {
  background-image: url("gabon.10230441.png");
}

.pic.greece {
  background-image: url("greece.87784d10.png");
}

.pic.grenada {
  background-image: url("grenada.d2e63253.png");
}

.pic.haiti {
  background-image: url("haiti.b5c31244.png");
}

.pic.india {
  background-image: url("india.a16bb53b.png");
}

.pic.indonesia {
  background-image: url("indonesia.d837574b.png");
}

.pic.italy {
  background-image: url("italy.1dbc86a7.png");
}

.pic.japan {
  background-image: url("japan.8949e9bb.png");
}

.pic.kazakhstan {
  background-image: url("kazakhstan.b8f2c1c2.png");
}

.pic.kyrgyzstan {
  background-image: url("kyrgyzstan.ca832736.png");
}

.pic.libya {
  background-image: url("libya.4e03f037.png");
}

.pic.mexico {
  background-image: url("mexico.dcf6c9f1.png");
}

.pic.mozambique {
  background-image: url("mozambique.fb601890.png");
}

.pic.norway {
  background-image: url("norway.67d4269a.png");
}

.pic.papua-new-guinea {
  background-image: url("papua-new-guinea.a72bfbe8.png");
}

.pic.peru {
  background-image: url("peru.b44d6647.png");
}

.pic.philippines {
  background-image: url("philippines.6f472b71.png");
}

.pic.russia {
  background-image: url("russia.e3547c0e.png");
}

.pic.scotland {
  background-image: url("scotland.595aae99.png");
}

.pic.san-marino {
  background-image: url("san-marino.20ec8dc4.png");
}

.pic.singapore {
  background-image: url("singapore.c2cce677.png");
}

.pic.slovenia {
  background-image: url("slovenia.adf01528.png");
}

.pic.sri-lanka {
  background-image: url("sri-lanka.dcaf4b28.png");
}

.pic.tanzania {
  background-image: url("tanzania.21f49950.png");
}

.pic.turkey {
  background-image: url("turkey.5d47a42d.png");
}

.pic.vanuatu {
  background-image: url("vanuatu.e6c3ab0c.png");
}

.pic.venezuela {
  background-image: url("venezuela.21cc0eeb.png");
}

.pic.yemen {
  background-image: url("yemen.b69e2df6.png");
}
/*# sourceMappingURL=index.7867d3df.css.map */
